import React, { useRef, useEffect, useState } from "react";
import d3 from "../utils/d3-importer";
import { groupBy } from "lodash";
import { format, parse } from "date-fns";
import data from "../../data/data.json";
import useWindowSize from "../components/hooks/useWindowSize";

function TimeLine() {
  const svgEl = useRef(null);
  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
    drawChart();
  }, [isMobile]);

  function drawChart() {
    const finalData = [];
    const margin = { top: 5, right: 20, bottom: 30, left: 20 };
    const width = 800;
    const height = 500;
    const svgWidth = width - margin.right - margin.left;
    const svgHeight = height - margin.top - margin.bottom;
    let units = groupBy(data, value =>
      format(new Date(value.incident_date), "yyyy-MMM")
    );
    for (const unit in units) {
      finalData.push({
        date: parse(unit, "yyyy-MMM", new Date()),
        counts: Object.values(units[unit]).length,
        incidents: Object.values(units[unit])
      });
    }
    const x = d3
      .scaleTime()
      .range([0, svgWidth])
      .domain(d3.extent(finalData, d => d.date));
    const y = d3
      .scaleLinear()
      .range([0, svgHeight])
      .domain([d3.max(finalData, d => d.counts), 0]);
    const xAxis = d3
      .axisBottom(x)
      .ticks(d3.timeMonth.every(8))
      .tickFormat(d3.timeFormat("%b%y"));

    const yAxis = d3.axisLeft(y);
    const chart = d3
      .select(svgEl.current)
      .attr("width", svgWidth + margin.left + margin.right)
      .attr("height", svgHeight + margin.top + margin.bottom);

    const g = chart.append("g").attr("transfrom", "translate(20,5)");
    g.append("g")
      .call(xAxis)
      .attr("transform", `translate(30, ${svgHeight})`);
    g.append("g")
      .call(yAxis)
      .attr("transform", `translate(30, 5)`);

    g.append("g")
      .attr("transform", "translate(50,10)")
      .selectAll("dot")
      .data(finalData)
      .enter()
      .append("circle")
      .attr("cx", function(d) {
        return x(d.date);
      })
      .attr("cy", function(d) {
        return y(d.counts);
      })
      .attr("r", 6.2)
      .style("fill", "#60323D")
      .style("opacity", "0.7")
      .attr("stroke", "white");
  }

  return isMobile ? <div></div> : <svg ref={svgEl}></svg>;
}

export default TimeLine;
